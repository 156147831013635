export type Group = 'Board' | 'Marketing' | 'Operations';

export interface IMember {
    name: string;
    company: string;
    address1: string;
    address2: string;
    phone: string;
    email: string;
    group: Group[];
    isChairman?: boolean;
}

export const members: IMember[] = [
    {
        name: 'Tony Moyer',
        company: 'Roundhouse, Inc.',
        address1: 'P.O. Box 4093',
        address2: 'Auburn, AL 36830',
        phone: '(706) 442-7935',
        email: 'tony.lcp@icloud.com',
        group: ['Board', 'Marketing', 'Operations'],
        isChairman: true,
    },
    {
        name: 'Paul Jones',
        company: 'Marble Foods',
        address1: '2 Brook St.',
        address2: 'Warren, PA 16365',
        phone: '(814) 688-6993',
        email: 'paul.marblefoods@westpa.net',
        group: ['Board', 'Marketing', 'Operations'],
    },
    {
        name: 'Craig McClure',
        company: 'Restaurant Mngt Group',
        address1: '2 Charter Court #2',
        address2: 'Johnson City, TN 37604',
        phone: '(714) 270-1389',
        email: 'cmcclure@rmg766.com',
        group: ['Board'],
    },
    {
        name: 'John Gauthier',
        company: 'G & H Pizza',
        address1: '1601 Bessemer #A',
        address2: 'Turlock CA 95380',
        phone: '(209) 404-5780',
        email: 'john@pizzapeople.net',
        group: ['Board'],
    },
    {
        name: 'Sheena Plocharczyk',
        company: 'Ride or Die Dough, Inc.',
        address1: '3367 E. Morgan Road',
        address2: 'Ann Arbor, MI 48108',
        phone: '(734) 635-3565',
        email: 'rideordiedough@gmail.com',
        group: ['Board', 'Operations'],
    },
    {
        name: 'Jason Hotchkiss',
        company: 'LC of San Antonio, Inc.',
        address1: '925 Coronado Blvd., Ste 100',
        address2: 'Universal City, TX 78148',
        phone: '(210) 410-4045',
        email: 'jason@lcpsa.net',
        group: ['Board'],
    },
    {
        name: 'Steve Price',
        company: 'Magnum Foods',
        address1: '7205 N. Robinson',
        address2: 'Oklahoma City, OK 73116',
        phone: '(405) 767-3313',
        email: 'sprice@magnumfoods.net',
        group: ['Board'],
    },
    {
        name: "Patrick O'Connell",
        company: 'Aces, LLC',
        address1: '121 N. Lowell Street',
        address2: 'Ironwood, MI 49938',
        phone: '(540) 489-3434',
        email: 'pocboston@yahoo.com',
        group: ['Board'],
    },
    {
        name: 'John Kingston',
        company: 'Big Cheese, LLC',
        address1: '630 Friendship Church Rd',
        address2: 'Powder Springs, GA 30127',
        phone: '(423) 596-2840',
        email: 'john4994@comcast.net',
        group: ['Board', 'Operations'],
    },
    {
        name: 'Herve Ouellette',
        company: 'A&H Pizza Co., LLC',
        address1: '9631 E Javelina Ave.',
        address2: 'Mesa, AZ 85209',
        phone: '(480) 363-8993',
        email: 'herve@ahpizza.com',
        group: ['Board'],
    },
    {
        name: 'Eric Sorensen',
        company: 'Caesars of Dallas, LLC',
        address1: '4203 Beltway #1',
        address2: 'Addison, TX 75001',
        phone: '(214) 636-0988',
        email: 'eric.s@lcdfw.com',
        group: ['Board', 'Marketing'],
    },
    {
        name: 'Jason Zieman',
        company: 'Zieman Restaurant Co.',
        address1: '184 Currie Hall Pkwy #1',
        address2: 'Kent, OH 44240',
        phone: '(330) 338-0431',
        email: 'jasonzieman@gmail.com',
        group: ['Board', 'Marketing', 'Operations'],
    },
];
