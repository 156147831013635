import React from 'react';
import styled from 'styled-components';

// components
import AccordionSection from './accordion-section';

// styled components
import AccordionStyles from './styles/accordion-styles';

// images
// @ts-ignore
import Placeholder from '../images/placeholder-person.jpg';
import { members, IMember, Group } from './member-data/members';

// @ts-ignore
const PersonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.black};

    p,
    img {
        margin: 0;
    }

    .imagePlaceholder {
        width: 100px;
        height: 100px;
    }

    .personDetails {
        display: flex;
        flex-direction: column;
        font-size: 18px;
        padding: 0;
        margin: 0;
        padding-left: 40px;
    }

    .name {
        font-weight: bold;
        display: flex;
    }

    @media (max-width: 768px) {
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;

        .personDetails {
            padding-left: 0;
        }

        img {
            display: none;
        }
    }
`;

// @ts-ignore
const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 225px;

    @media (max-width: 768px) {
        padding-top: 10px;
    }
`;

// @ts-ignore
const SectionContainer = styled.div`
    padding: 35px 0;

    @media (max-width: 768px) {
        display: flex;
        align-items: flex-start;
    }
`;

interface ICommitteeSection {
    description?: string;
    members: IMember[];
    title: string;
    type: Group;
}

const CommitteeSection: React.FC<ICommitteeSection> = ({
    members,
    description,
    title,
    type,
}) => (
    <AccordionSection title={title}>
        {description && <p>{description}</p>}
        {members.map((member, ind) => (
            <SectionContainer
                key={`${member.name}-${ind}`}
                className="section__container"
            >
                <div className="person">
                    <PersonContainer>
                        <img
                            alt="Placeholder person"
                            className="imagePlaceholder"
                            src={Placeholder}
                        />
                        <div className="personDetails">
                            <span className="name">
                                {member.name}
                                {member.isChairman && type === 'Board' && (
                                    <span>, Chairman</span>
                                )}
                            </span>
                            <span className="address">{member.company}</span>
                            <span className="address">{member.address1}</span>
                            <span className="address">{member.address2}</span>
                        </div>
                    </PersonContainer>
                </div>
                <ContactContainer>
                    <h3>Contact</h3>
                    <a href={`tel:${member.phone}`}>{member.phone}</a>
                    <a href={`mailto:${member.email}`}>{member.email}</a>
                </ContactContainer>
            </SectionContainer>
        ))}
    </AccordionSection>
);

const BoardCommitteeAccordion = () => {
    const committees = {
        Board: members.filter(member => member.group.includes('Board')),
        Marketing: members.filter(member => member.group.includes('Marketing')),
        Operations: members.filter(member =>
            member.group.includes('Operations')
        ),
    };

    const committeeDescriptions = {
        Marketing:
            'National Marketing Advisory Committee (NMAC) consists of several individuals including corporate Marketing personnel and IOLCF member franchisees, chosen by the association. This committee meets six times per year either in person or via teleconference to allow for input into national marketing decisions. Franchisees serving on the committee are required to travel to Detroit for meetings with LCE and serve a two year term.',
        Operations:
            'National Operations Advisory Committee (NOAC) consists of several individuals including corporate Franchise Services personnel and IOLCF member franchisees, chosen by the association. This committee meets on an as needed basis to allow for input into decisions regarding product specifications, quality, new methods of product distribution and changes to the franchise agreement. Franchisees serve a two year term.',
    };

    const committeeTitle = {
        Board: 'Board of Directors',
        Marketing: 'Marketing Committee',
        Operations: 'Operations Committee',
    };

    return (
        <AccordionStyles>
            {Object.entries(committees).map(([name, members]) => (
                <CommitteeSection
                    key={name}
                    members={members}
                    description={committeeDescriptions[name]}
                    title={committeeTitle[name]}
                    type={name as Group}
                />
            ))}
        </AccordionStyles>
    );
};

export default BoardCommitteeAccordion;
