import styled from 'styled-components';

const BoardCommitteeStyles = styled.div`
  .Accord__container {
    margin: 0 auto;
    padding: 30px;
    max-width: 1060px;
  }
`;

export default BoardCommitteeStyles;
